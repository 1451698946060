<template>
  <tr class="contact-row">
    <!-- <td style="min-width: 30px !important;">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td> -->
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    
    <!-- ID Column -->
    <td class="contact-cell contact-id">
      <span class="contact-badge">#{{contact.id}}</span>
    </td>

    <!-- Contact Info Column -->
    <td class="contact-cell contact-info">
      <v-list class="talk-toolbar__title contact-info__list" color="transparent" two dense flat>
        <v-list-tile avatar flat color="transparent" class="talk-toolbar__title-text contact-info__tile">
          <v-list-tile-avatar>
            <v-avatar size="40" class="contact-avatar">

              <img v-if="contact.file && contact.file.url" :src="contact.file.url" alt="Avatar">
              <img v-else-if="contact.type == 1" src="static/icons/user_icon_1.png" alt="Usuário">
              <group-icon v-else />

            </v-avatar>
          </v-list-tile-avatar>
          
          <v-list-tile-content>
            <v-list-tile-title class="contact-name">
              <strong v-if="props.item">{{contact.name | stringlength(30)}}</strong>
              <span v-else>Nome não definido</span>
            </v-list-tile-title>
            
            <v-list-tile-sub-title class="contact-details">
              <!-- Status Icons -->
              <v-tooltip v-if="contact.checked == 2" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="success" small>verified</v-icon>
                </template>
                <span>Contato validado</span>
              </v-tooltip>
              
              <v-tooltip v-if="contact.checked == 3" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error" small>do_not_disturb_on</v-icon>
                </template>
                <span>Contato inválido</span>
              </v-tooltip>

              <!-- Phone Number -->
              <span class="contact-phone">
                <template v-if="contact.type == 1">
                  <strong v-if="contact.number.toString().length == 12">
                    {{ contact.number | mask('+## (##) ####-####') }}
                  </strong>
                  <strong v-else-if="contact.number.toString().length == 13">
                    {{ contact.number | mask('+## (##) # ####-####') }}
                  </strong>
                  <span v-else>{{ contact.number }}</span>
                </template>
                <span v-else>---</span>
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </td>

    <!-- Status Column -->
    <td class="contact-cell contact-status">
      <div v-if="contact.opened_talks && contact.opened_talks.length > 0" class="status-active">
        <span class="status-count">{{ contact.opened_talks.length }}</span>
        <div 
          v-if="$WiCheckPermission('48eab18e')" 

          class="status-text clickable"
          @click="showOpenedTalks()"
        >
          <strong>
            Atendimento<span v-if="contact.opened_talks.length > 1">s</span> em andamento
          </strong>
          <span class="view-link">Clique para ver</span>
        </div>
        <div v-else class="status-text">
          <strong>
            Atendimento<span v-if="contact.opened_talks.length > 1">s</span> em andamento
          </strong>
          <span class="view-link">Clique para ver</span>
        </div>
      </div>

      <div v-else-if="contact.last_event_at" class="status-last-message">
        <span>Última mensagem em:</span>
        <strong>{{ contact.last_event_at | moment('DD/MM/YYYY HH:mm') }}</strong>
      </div>

      <div v-else class="status-no-interaction">
        Sem registro de interações
      </div>
    </td>
  </tr>
</template>
<script>
  import GroupIcon from '@/modules/talk/components/icons/GroupIcon.vue';
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalkContacts',
    computed: {
      contact () {
        return this.props.item
      }
    },
    methods: {
      openTicket: function (ticketId) {
        this.$router.push({name: 'ticket', params: {id: ticketId}})
      },
      newTalk: function () {
        this.$WiApiPost({
          uri: 'talk/talk',
          data: {
            talk_contact_id: this.contact.id,
            talk_channel_id: this.contact.talk_channel_id,
            operator_id: this.$Auth.user().id,
            status: 2
          },
          config: {msgLoad: true, msgSucess: true},
          callback: (response) => {
            this.$router.push({name: 'talk-view-messages', params: {id: response.sucess.id}})
          }
        })
      },
      showOpenedTalks: function () {
        this.$WiListDialog({
          wiConfig: 'talk-opened',
          filter: {
            talk_contact_id: this.contact.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      GroupIcon,
      WiListDataTableActions
    }
  }
</script>
<style>
  td, th {
    padding: 0 5px !important;
  }

  .talk-toolbar__title-text .v-list__tile {
    padding: 0 !important;
  }

  .contact-row {
    transition: background-color 0.2s ease;
  }

  /* Tema claro */
  .theme--light .contact-row:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  /* Tema escuro */
  .theme--dark .contact-row:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .contact-cell {
    vertical-align: middle;
  }

  .contact-badge {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 0.9rem;
  }

  .theme--light .contact-badge {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.87);
  }

  .theme--dark .contact-badge {
    background-color: #424242;
    color: #fff;
  }

  .contact-info__list {
    padding: 0 !important;
  }

  .contact-info__list >>> .theme--light.v-list {
    background: transparent !important;
  }
  .contact-info__list >>> .theme--dark.v-list {
    background: transparent !important;
  }

  .contact-info__tile {
    height: auto !important;
  }

  .contact-avatar {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }

  .theme--light .contact-avatar {
    border: 1px solid #e0e0e0;
  }

  .theme--dark .contact-avatar {
    border: 1px solid #424242;
  }

  .theme--light .contact-name {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .theme--dark .contact-name {
    color: #fff !important;
  }

  .contact-details {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-top: 2px;
  }

  .theme--light .contact-phone {
    color: rgba(0, 0, 0, 0.6);
  }

  .theme--dark .contact-phone {
    color: rgba(255, 255, 255, 0.7);
  }

  .status-active {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .status-count {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1976d2;
  }

  .theme--dark .status-count {
    color: #64B5F6;
  }

  .status-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .status-text.clickable {
    cursor: pointer;
    transition: opacity 0.2s ease;
  }

  .status-text.clickable:hover {
    opacity: 0.8;
  }

  .view-link {
    color: #1976d2;
    font-size: 0.875rem;
  }

  .theme--dark .view-link {
    color: #64B5F6;
  }

  .status-last-message {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 0.875rem;
  }

  .theme--light .status-last-message {
    color: rgba(0, 0, 0, 0.6);
  }

  .theme--dark .status-last-message {
    color: rgba(255, 255, 255, 0.7);
  }

  .status-no-interaction {
    font-size: 0.875rem;
  }

  .theme--light .status-no-interaction {
    color: rgba(0, 0, 0, 0.6);
  }

  .theme--dark .status-no-interaction {
    color: rgba(255, 255, 255, 0.7);
  }
</style>